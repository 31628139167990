import {
  Component,
  HostListener,
  Inject,
  OnInit,
  PLATFORM_ID,
  OnDestroy,
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';

import { Product, Advert } from 'interfaces/common';
import { ProductService, UserService, HeaderService } from 'services/common';
import { UtilsService, UiService } from 'services/core';
import { OpenDalogComponent } from './open-dalog/open-dalog.component';
import { DATABASE_KEY } from '@/core/utils/global-variable';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  /* HEADER FIXED */
  headerFixed = false;
  isCookieBannerVisible = true;
  /**
   * Show and hide responsive nav.
   * Get subject data.
   */
  product: Advert | null = null;
  // FilterData
  filter: any = null;

  // Subscriptions
  private subAllProductByUser!: Subscription;
  showHideResponsiveNav = false;
  private subGetProductDetail!: Subscription;
  private subGetProductByID!: Subscription;
  changeColor = false;
  isLoggedIn = false;

  constructor(
    private _headerService: HeaderService,
    public dialog: MatDialog,
    public router: Router,
    public utilsService: UtilsService,
    public uiService: UiService,
    private productService: ProductService,
    private userService: UserService,
    @Inject(PLATFORM_ID) private platformId: any
  ) {}

  ngOnInit(): void {
    const MILLISECONDS_IN_MONTH = 30 * 24 * 60 * 60 * 1000;
    if (isPlatformBrowser(this.platformId)) {
      const lastDialogShown = localStorage.getItem('lastDialogShown');
      this.isLoggedIn = this.userService.getUserStatus();
      this.isCookieBannerVisible =
        !lastDialogShown ||
        Date.now() - parseInt(lastDialogShown, 10) > MILLISECONDS_IN_MONTH;
    }
    this._headerService.headerColorChange.subscribe(res => {
      this.changeColor = res;
    });
    // this.getSingleProduct();
    // this.openComponentDialog()
  }

  private getSingleProduct() {
    if (
      isPlatformBrowser(this.platformId) &&
      localStorage.getItem(DATABASE_KEY.encryptUserLogin)
    ) {
      this.subAllProductByUser = this.productService
        .getProductByUser()
        .subscribe({
          next: res => {
            if (res.success) {
              this.product = res.data;
              this.routeRedirects();
            }
          },
          error: err => console.log(err),
        });
    }
  }
  private getProductById(id: number): Observable<Product> {
    return new Observable<Product>(observer => {
      this.subGetProductByID = this.productService
        .getProductByShortId(id)
        .subscribe(
          res => {
            if (res.success) {
              observer.next(res.data);
              observer.complete();
            }
          },
          err => observer.error(err)
        );
    });
  }
  /**
   * RESPONSIVE NAV
   * HEADER FIXED
   */
  onShowHideResponsiveNav() {
    this.showHideResponsiveNav = !this.showHideResponsiveNav;
  }
  async onShowHideResponsive() {
    this.showHideResponsiveNav = !this.showHideResponsiveNav;
    // TODO: @sewren : update the logic condition

    // if(this.products?.length){
    //   // this.uiService.warn('Already added one post')
    //   this.router.navigate(['/account/my-list']).then()
    //   // return;
    // }
    this.getSingleProduct();
  }

  routeRedirects() {
    const productId = Number(this.product?.shortId);
    if (!localStorage.getItem(DATABASE_KEY.encryptUserLogin)) {
      this.router.navigate(['/login']);
      return;
    } else if (!productId || Number.isNaN(productId)) {
      this.router.navigate(['/create-new']);
      return;
    } else if (productId && !Number.isNaN(productId)) {
      this.router.navigate(['/create-new'], { queryParams: { id: productId } });
      return;
    }
  }

  @HostListener('window:scroll')
  scrollBody() {
    this.headerFixed = window.scrollY > 50;
  }

  /**
   * OPEN COMPONENT DIALOG
   */

  public openComponentDialog() {
    this.dialog.open(OpenDalogComponent, {
      // panelClass: ['theme-dialog', 'full-screen-modal'],
      disableClose: true,
      height: 'auto',
      width: '350px',
      position: { left: '10px', bottom: '10px' },
    });
  }

  isHideCookie() {
    this.isCookieBannerVisible = false;
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem('lastDialogShown', Date.now().toString());
    }
  }

  isShowCookie() {
    const MILLISECONDS_IN_MONTH = 30 * 24 * 60 * 60 * 1000;
    if (isPlatformBrowser(this.platformId)) {
      const lastDialogShown = localStorage.getItem('lastDialogShown');
      this.isCookieBannerVisible =
        !lastDialogShown ||
        Date.now() - parseInt(lastDialogShown, 10) > MILLISECONDS_IN_MONTH;
    }
  }

  onLogout() {
    this.userService.userLogOut();
    this.showHideResponsiveNav = !this.showHideResponsiveNav;
    window.location.reload();
  }

  ngOnDestroy(): void {
    if (this.subAllProductByUser) this.subAllProductByUser.unsubscribe();
    if (this.subGetProductByID) this.subGetProductByID.unsubscribe();
    if (this.subGetProductDetail) this.subGetProductDetail.unsubscribe();
  }
}
