import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Favorite } from '../../interfaces/common/favorite.interface';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Resp } from 'interfaces/core';

const API_URL = environment.apiBaseLink + '/api/favorite/';

@Injectable({ providedIn: 'root' })
export class FavoriteService {
  /**
   * REFRESH LOCAL STORED CART
   */
  private refreshStoredFavorite = new Subject<void>();

  get refreshStoredFavorite$() {
    return this.refreshStoredFavorite;
  }

  needRefreshStoredFavorite$() {
    this.refreshStoredFavorite.next();
  }

  constructor(private httpClient: HttpClient) {}

  /**
   * addToFavorite
   * getFavoriteByUserId
   * deleteFavoriteById
   */

  addToFavorite(data: { productId: string }) {
    const payload = { advert: data.productId };
    return this.httpClient.post<Resp>(API_URL + 'add-to-favorite', payload);
  }

  getFavoriteByUser() {
    return this.httpClient.get<Resp<Array<Favorite>>>(
      API_URL + 'get-favorite-by-user'
    );
  }

  deleteFavoriteById(id: string) {
    return this.httpClient.delete<Resp>(API_URL + 'delete/' + id);
  }
}
