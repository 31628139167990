import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Resp, FilterData } from 'interfaces/core';
import { Blog } from 'interfaces/common';

const API_URL = environment.apiBaseLink + '/api/blog/';

@Injectable({ providedIn: 'root' })
export class BlogService {
  constructor(private httpClient: HttpClient) {}

  /**
   * BLOG HTTP REQUEST
   * getAllBlog()
   * getBlogById()
   */
  getAllBlog(filterData: FilterData) {
    return this.httpClient.post<Resp<Array<Blog>>>(
      API_URL + 'get-all/',
      filterData
    );
  }

  getBlogById(_id: string) {
    const params = new HttpParams();
    return this.httpClient.get<Resp<Blog>>(API_URL + _id, { params });
  }
}
