import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  CapturePaymentDetail,
  SubscriptionDetails,
} from 'src/app/interfaces/common/subscription.interface';
import { Resp } from 'src/app/interfaces/core/response-payload.interface';
import { environment } from 'src/environments/environment';

const API_URL = `${environment.apiBaseLink}/api`;

@Injectable({ providedIn: 'root' })
export class SubscriptionService {
  constructor(private httpClient: HttpClient) {}

  getAllSubscription() {
    return this.httpClient.get<Resp<Array<SubscriptionDetails>>>(
      `${API_URL}/subscription`,
      {}
    );
  }

  buyVipSubscription(userId: string, subscriptionId: string) {
    return this.httpClient.post<Resp>(
      `${API_URL}/user/activate-vip/${userId}`,
      { subscriptionId: subscriptionId }
    );
  }

  vipSubscriptionPayment(userId: string, subscriptionId: string) {
    return this.httpClient.post<Resp & { session?: { url?: string } }>(
      `${API_URL}/user/purchase-package/${userId}`,
      { subscriptionId: subscriptionId }
    );
  }

  vipSubscriptionPaymentWithPoints(userId: string, subscriptionId: string) {
    return this.httpClient.post<Resp>(
      `${API_URL}/point/purchase-vip-by-points/${userId}`,
      { subscriptionId: subscriptionId }
    );
  }

  getUserSubscription() {
    return this.httpClient.get<Resp>(`${API_URL}/user/subscription-list`, {});
  }

  getTransactionHistory(user_id: string) {
    return this.httpClient.get<Resp>(
      `${API_URL}/transaction-history/get-transaction/${user_id}`
    );
  }

  capturePayment(userId: string, data: CapturePaymentDetail) {
    return this.httpClient.post<Resp>(
      `${API_URL}/user/capture-payment/${userId}`,
      data
    );
  }
}
