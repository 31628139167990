import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Orientation } from '../../interfaces/common/orientation.interface';
import { Resp, FilterData } from 'interfaces/core';

const API_URL = environment.apiBaseLink + '/api/orientation/';

@Injectable({ providedIn: 'root' })
export class OrientationService {
  constructor(private httpClient: HttpClient) {}

  /**
   * getAllOrientations
   */

  getAllOrientation(filterData: FilterData) {
    return this.httpClient.post<Resp<Array<Orientation>>>(
      API_URL + 'get-all/',
      filterData
    );
  }
}
