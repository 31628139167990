import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { Resp, FilterData } from 'interfaces/core';
import { BodyType } from 'interfaces/common';

const API_URL = environment.apiBaseLink + '/api/bodyType/';

@Injectable({ providedIn: 'root' })
export class BodyTypeService {
  constructor(private httpClient: HttpClient) {}

  /**
   * addBodyType
   * getAllBodyTypes
   */

  addBodyType(data: BodyType): Observable<Resp> {
    return this.httpClient.post<Resp>(API_URL + 'add', data);
  }

  getAllBodyType(filterData: FilterData) {
    return this.httpClient.post<Resp<Array<BodyType>>>(
      API_URL + 'get-all/',
      filterData
    );
  }
}
