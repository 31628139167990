import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class StorageService {
  /**
   * SESSION STORAGE
   */
  setInSession(key: string, data: any) {
    sessionStorage.setItem(key, JSON.stringify(data));
  }

  getFromSession<T = any>(key: string): T {
    const data = sessionStorage.getItem(key);
    if (data) {
      return JSON.parse(data);
    }
    throw new Error(`Unable to get local data for ${key} key`);
  }

  removeFromSession(key: string) {
    sessionStorage.removeItem(key);
  }

  /**
   * LOCAL STORAGE
   */
  setInLocal(key: string, data: any) {
    localStorage.setItem(key, JSON.stringify(data));
  }

  getFromLocal<T = any>(key: string): T {
    const data = localStorage.getItem(key);
    if (data) {
      return JSON.parse(data);
    }
    throw new Error(`Unable to get local data for ${key} key`);
  }

  removeFromLocal(key: string) {
    localStorage.removeItem(key);
  }
}
