<div class="dialog">
  <div mat-dialog-content>
    <p>
      This website uses cookies to ensure you get the best esperience on our
      website.
      <span mat-dialog-close routerLink="/privacy-policy">Learn more</span>
    </p>
  </div>
  <div class="dialog-btn" mat-dialog-actions>
    <button class="dialog-button">Got it</button>
  </div>
</div>
