import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslationService } from 'src/app/services/core/translation.service';
import { Language } from 'interfaces/common';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  currentLang: Language = 'da';

  constructor(
    private translationService: TranslationService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.currentLang = this.translationService.getCurrentLanguage();
  }

  toggleLanguage() {
    const lang: Language = this.currentLang === 'en' ? 'da' : 'en';
    this.translationService.useLanguage(lang);
    this.currentLang = lang;

    // Update the URL with the new language parameter
    const urlTree = this.router.createUrlTree([], {
      queryParams: { lang },
      queryParamsHandling: 'merge',
      preserveFragment: true,
    });
    this.router.navigateByUrl(urlTree);
  }

  get copyrightYear() {
    return new Date().getFullYear();
  }
}
