import {
  Component,
  Inject,
  OnInit,
  PLATFORM_ID,
  afterNextRender,
} from '@angular/core';
import { UserService } from './services/common/user.service';
import { environment } from 'src/environments/environment';
import { isPlatformBrowser } from '@angular/common';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    private userService: UserService,
    @Inject(PLATFORM_ID) private platformId: object
  ) {
    afterNextRender(() => {
      this.userService.autoUserLoggedIn();
    });
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) this.addPreconnectLink();
  }

  private addPreconnectLink(): void {
    const link = document.createElement('link');
    link.rel = 'preconnect';
    link.href = environment.ftpBaseLink;
    document.head.appendChild(link);
  }
}
