import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Division } from 'interfaces/common';
import { Resp, FilterData } from 'interfaces/core';

const API_URL = environment.apiBaseLink + '/api/division/';

@Injectable({ providedIn: 'root' })
export class DivisionService {
  constructor(private httpClient: HttpClient) {}

  /**
   * addDivision()
   * getAllDivisions()
   * getAllDivisionsdd()
   */

  getAllDivisions(filterData: FilterData) {
    return this.httpClient.post<Resp<Array<Division>>>(
      API_URL + 'get-all',
      filterData
    );
  }

  getAllDivisionsdd(filterData: FilterData) {
    return this.httpClient.post<Resp<Array<Division>>>(
      API_URL + 'get-all-div',
      filterData
    );
  }
}
