<div class="header">
  <app-header></app-header>
</div>
<!-- END! header -->

<div class="page-render">
  <router-outlet></router-outlet>
</div>
<!-- END! page-render -->

<div class="footer">
  <app-footer></app-footer>
</div>
<!-- END! footer -->
