<footer class="footer-top">
  <div class="container">
    <div class="footer-child">
      <div class="footer-items">
        <div class="footer-item">
          <h2>{{ 'FOOTER.MASSAGE' | translate }}</h2>
          <div class="footer-line"></div>
          <ul>
            <li>
              <a routerLink="/massage-aalborg">{{
                'FOOTER.MASSAGE_AALBORG' | translate
              }}</a>
            </li>
            <li>
              <a routerLink="/massage-odense">{{
                'FOOTER.MASSAGE_ODENSE' | translate
              }}</a>
            </li>
            <li>
              <a routerLink="/massage-koebenhavn">{{
                'FOOTER.MASSAGE_KOEBENHAVN' | translate
              }}</a>
            </li>
            <li>
              <a routerLink="/massage-aarhus">{{
                'FOOTER.MASSAGE_AARHUS' | translate
              }}</a>
            </li>
          </ul>
        </div>

        <div class="footer-item">
          <h2>{{ 'FOOTER.ESCORT_LOCAL' | translate }}</h2>
          <div class="footer-line"></div>
          <ul>
            <li>
              <a routerLink="/escort-aalborg">{{
                'FOOTER.ESCORT_AALBORG' | translate
              }}</a>
            </li>
            <li>
              <a routerLink="/escort-odense">{{
                'FOOTER.ESCORT_ODENSE' | translate
              }}</a>
            </li>
            <li>
              <a routerLink="/escort-koebenhavn">{{
                'FOOTER.ESCORT_KOEBENHAVN' | translate
              }}</a>
            </li>
            <li>
              <a routerLink="/escort-aarhus">{{
                'FOOTER.ESCORT_AARHUS' | translate
              }}</a>
            </li>
            <li>
              <a routerLink="/escort-valby">{{
                'FOOTER.ESCORT_VALBY' | translate
              }}</a>
            </li>
          </ul>
        </div>

        <div class="footer-item">
          <h2>{{ 'FOOTER.INFORMATION' | translate }}</h2>
          <div class="footer-line"></div>
          <ul>
            <li>
              <a routerLink="/privacy-policy">{{
                'FOOTER.PRIVACY_COOKIE_POLICY' | translate
              }}</a>
            </li>
            <li>
              <a routerLink="/blogs">{{
                'FOOTER.BLOG_ARTICLES' | translate
              }}</a>
            </li>
          </ul>
        </div>

        <div class="footer-item">
          <h2>{{ 'FOOTER.READ_MORE' | translate }}</h2>
          <div class="footer-line"></div>
          <ul>
            <li>
              <a routerLink="/terms-and-conditions">{{
                'FOOTER.TERMS_CONDITIONS' | translate
              }}</a>
            </li>
            <li>
              <a routerLink="/advertising">{{
                'FOOTER.ADVERTISEMENT' | translate
              }}</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <div class="container">
    <div class="footer-bottom-child">
      <p>OG Media ApS Tankedraget 7, 4., 9000 Aalborg</p>
      <div class="payment-logo-container">
        <img src="assets/images/payment-logo/visa.svg" alt="Visa" />
        <img src="assets/images/payment-logo/mastercard.svg" alt="Mastercard" />
        <div class="flag-container" role="button" (click)="toggleLanguage()">
          @if (currentLang === 'en') {
            <img
              src="../../../../assets/images/flags/dk.svg"
              alt="Danish Flag" />
          }
          @if (currentLang === 'da') {
            <img src="../../../../assets/images/flags/en.svg" alt="UK Flag" />
          }
        </div>
      </div>
    </div>
  </div>
</footer>

<!-- <footer class="footer-bottom">
<div class="container">
  <div class="footer-bottom-child">
    <p>
      Copyright {{ copyrightYear }} &copy; demo limited. ALL RIGHTS
      RESERVED
    </p>
  </div>
  END! footer-bottom-child -->
<!-- </div>
</footer> -->
<!-- END! footer-bottom -->
