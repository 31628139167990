import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CustomPreloadingStrategy } from './core/utils/preloading-strategy';
import { PagesComponent } from './pages/pages.component';
import { UserAuthGuard } from './auth-guard/user-auth.guard';
import { UserAuthStateGuard } from './auth-guard/user-auth-state.guard';

const routes: Routes = [
  {
    path: '',
    component: PagesComponent,
    children: [
      // {
      //   path: '',
      //   redirectTo: 'home',
      //   pathMatch: 'full'
      // },
      {
        path: '',
        loadChildren: () =>
          import('./pages/home/home.module').then(m => m.HomeModule),
      },
      {
        path: 'login',
        loadChildren: () =>
          import('./pages/user/login/login.module').then(m => m.LoginModule),
        canActivate: [UserAuthStateGuard],
      },
      {
        path: 'forgot-password',
        loadChildren: () =>
          import('./pages/user/forgot-password/forgot-password.module').then(
            m => m.ForgotPasswordModule
          ),
        canActivate: [UserAuthStateGuard],
      },
      {
        path: 'reset-password',
        loadChildren: () =>
          import('./pages/user/reset-password/reset-password.module').then(
            m => m.ResetPasswordModule
          ),
        canActivate: [UserAuthStateGuard],
      },
      {
        path: 'sign-up',
        loadChildren: () =>
          import('./pages/sign-up/sign-up.module').then(m => m.SignUpModule),
        canActivate: [UserAuthStateGuard],
      },
      {
        path: 'ads',
        loadChildren: () =>
          import('./pages/product-list/product-list.module').then(
            m => m.ProductListModule
          ),
      },
      {
        path: 'ad-details',
        loadChildren: () =>
          import('./pages/product-details/product-details.module').then(
            m => m.ProductDetailsModule
          ),
      },
      {
        path: 'account',
        loadChildren: () =>
          import('./pages/account/account.module').then(m => m.AccountModule),
        canActivate: [UserAuthGuard],
      },
      {
        path: 'create-new',
        loadChildren: () =>
          import('./pages/create-ads-three/create-ads-three.module').then(
            m => m.CreateAdsThreeModule
          ),
        canActivate: [UserAuthGuard],
      },
      {
        path: 'blogs',
        loadChildren: () =>
          import('./pages/blogs/blogs.module').then(m => m.BlogsModule),
      },
      {
        path: 'annoncelight',
        loadChildren: () =>
          import('./pages/annonce-light-page/annonce-light-page.module').then(
            m => m.AnnonceLightPageModule
          ),
        data: { preload: false, delay: false },
      },
      {
        path: 'verified-otp',
        loadChildren: () =>
          import('./pages/verified-otp/verified-otp.module').then(
            m => m.VerifiedOtpModule
          ),
      },

      {
        path: 'blog-details/:id',
        loadChildren: () =>
          import('./pages/blog-details/blog-details.module').then(
            m => m.BlogDetailsModule
          ),
      },
      {
        path: 'pages',
        loadChildren: () =>
          import(
            './pages/additional-page-view/additional-page-view.module'
          ).then(m => m.AdditionalPageViewModule),
        data: { preload: false, delay: false },
      },
      {
        path: 'support',
        loadChildren: () =>
          import('./pages/support/support.module').then(m => m.SupportModule),
      },
      {
        path: 'privacy-policy',
        loadChildren: () =>
          import('./pages/privacy-policy/privacy-policy.module').then(
            m => m.PrivacyPolicyModule
          ),
      },
      {
        path: 'advertising',
        loadChildren: () =>
          import('./pages/advertising/advertising.module').then(
            m => m.AdvertisingModule
          ),
      },
      {
        path: 'terms-and-conditions',
        loadChildren: () =>
          import(
            './pages/terms-and-conditions/terms-and-conditions.module'
          ).then(m => m.TermsAndConditionsModule),
      },
      {
        path: ':id',
        loadChildren: () =>
          import('./pages/spot/spot.module').then(m => m.SpotModule),
      },
      {
        path: '**',
        redirectTo: 'home',
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
      preloadingStrategy: CustomPreloadingStrategy,
    }),
  ],
  providers: [UserAuthGuard, UserAuthStateGuard],
  exports: [RouterModule],
})
export class AppRoutingModule {}
