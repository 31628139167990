import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Resp } from 'interfaces/core';

const API_URL = environment.apiBaseLink + '/api/otp/';

@Injectable({ providedIn: 'root' })
export class OtpService {
  constructor(private httpClient: HttpClient) {}

  /**
   * generateOtpWithEmail()
   * validateOtpWithEmail()
   */

  generateOtpWithEmail(email: string) {
    return this.httpClient.post<Resp>(API_URL + 'generate-otp', {
      email,
    });
  }

  validateOtpWithEmail(data: { email: string; code: string }) {
    return this.httpClient.post<Resp>(API_URL + 'validate-otp', data);
  }
}
