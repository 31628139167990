import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Resp } from 'interfaces/core';

const API_URL = environment.apiBaseLink + '/api/point/';

@Injectable({ providedIn: 'root' })
export class PointsService {
  constructor(private httpClient: HttpClient) {}

  /**
   *  getPackage()
   *  purchasePoints()
   */

  getPackage() {
    return this.httpClient.get<Resp>(API_URL + 'get-packages');
  }
  purchasePoints(id: string, pointPackageId: string) {
    return this.httpClient.post<Resp>(API_URL + `purchase-points/${id}`, {
      pointPackageId,
    });
  }
}
