import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Advert, Product, ProductFilterGroup } from 'interfaces/common';
import { Resp, FilterData } from 'interfaces/core';

const API_URL = environment.apiBaseLink + '/api/advert/';

@Injectable({ providedIn: 'root' })
export class ProductService {
  constructor(private httpClient: HttpClient) {}

  /**
   * addProduct
   * getAllProducts
   * getProductById
   * updateMultipleProductById
   * deleteProductById
   * deleteMultipleProductById
   */

  addProductByUser(data: Product) {
    return this.httpClient.post<Resp>(API_URL + 'add-by-user', data);
  }

  getAllProducts(filterData: FilterData, searchQuery?: string) {
    let params = new HttpParams();
    if (searchQuery) {
      params = params.append('q', searchQuery);
    }
    return this.httpClient.post<
      Resp<Array<Advert>> & { filterGroup: ProductFilterGroup; count: number }
    >(API_URL + 'get-all', filterData, { params });
  }

  getAllCombinedProducts(filterData: FilterData, searchQuery: string) {
    const params = new HttpParams().set('q', searchQuery);
    return this.httpClient.post<
      Resp<Array<Advert>> & { filterGroup: ProductFilterGroup; count: number }
    >(API_URL + 'get-all-combined', filterData, { params });
  }

  getProductByUser() {
    return this.httpClient.get<Resp<Advert>>(
      API_URL + 'get-single-advert-user'
    );
  }

  getProductByShortId(shortId: number) {
    return this.httpClient.get<Resp<Product>>(
      API_URL + 'get-by-short-id/' + shortId
    );
  }

  updateUserProductById(shortId: number, data: Product) {
    return this.httpClient.put<Resp>(
      API_URL + 'update-by-user/' + shortId,
      data
    );
  }

  bumpProductById(id: string) {
    return this.httpClient.put<Resp>(API_URL + 'bump-by-user/' + id, {});
  }

  deleteProductByUserId(id: string) {
    return this.httpClient.delete<Resp>(API_URL + 'delete-by-user/' + id);
  }
}
