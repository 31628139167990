import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Area } from 'interfaces/common';
import { Resp, FilterData } from 'interfaces/core';

const API_URL = environment.apiBaseLink + '/api/area/';

@Injectable({ providedIn: 'root' })
export class AreaService {
  constructor(private httpClient: HttpClient) {}

  /**
   * getAllAreas()
   * getAreaByParentId()
   */

  getAllAreas(filterData: FilterData) {
    return this.httpClient.post<Resp<Array<Area>>>(
      API_URL + 'get-all',
      filterData
    );
  }

  getAreaByParentId(divisionId: string, select: string) {
    const params = new HttpParams().set('select', select);
    return this.httpClient.get<Resp<Array<Area>>>(
      API_URL + 'get-all-by-parent/' + divisionId,
      { params }
    );
  }
}
