import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { DateTime } from 'luxon';

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  constructor(@Inject(DOCUMENT) private document: Document) {}

  /**
   * UTILS
   */

  getDateWithCurrentTime(date: Date): Date {
    const now = DateTime.local();
    const newDate = DateTime.fromJSDate(date).set({
      hour: now.hour,
      minute: now.minute,
    });
    return newDate.toJSDate();
  }

  getDateString(date: Date, format?: string): string {
    const fm = format ? format : 'yyyy-MM-dd';
    return DateTime.fromJSDate(date).toFormat(fm);
  }

  /**
   * GET RANDOM NUMBER
   */
  getRandomInt(min: number, max: number): number {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  // REGEX SEARCH VARIABLE
  searchWithRegex = (
    collection: any[],
    term: string,
    opts: { caseSensitive: boolean; includedKeys: string[] }
  ) => {
    const filterBy = () => {
      const searchTerms = !opts.caseSensitive
        ? new RegExp(term, 'i')
        : new RegExp(term);
      return (obj: any) => {
        for (const key of Object.keys(obj)) {
          if (searchTerms.test(obj[key]) && opts.includedKeys.includes(key))
            return true;
        }
        return false;
      };
    };
    return collection.filter(filterBy());
  };

  getImageName(originalName: string): string {
    const array = originalName.split('.');
    array.pop();
    return array.join('');
  }

  mergeArrayString(array1: string[], array2: string[]): string[] {
    const c = array1.concat(array2);
    return c.filter((item, pos) => c.indexOf(item) === pos);
  }
}
